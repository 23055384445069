import { graphql, Link } from 'gatsby';
import React from 'react';

import Layout from '../layouts/Layout';
import Seo from '../components/Seo';
import Logo from '../assets/svg/logo.svg';
import { GatsbyImage } from 'gatsby-plugin-image';

const Tag = (props) => {
  const { posts, tag } = props.data;

  return (
    <Layout>
      <Seo
        title={tag.name}
        lang={tag.locale}
        path={`/blog/tags/${tag.contentful_id}`}
      />

      <div className="px-4 sm:px-6 mx-auto max-w-5xl">
        <div className="">
          <h1 className="text-center">Posts tagged {tag.name}</h1>

          <hr className="mx-auto mt-8 w-1/2" />

          <div>
            {posts.nodes.map((post) => (
              <div
                key={post.slug}
                className="grid grid-cols-1 md:grid-cols-3 gap-4 last:border-0 border-b"
              >
                <div>
                  {post.featuredImage ? (
                    <GatsbyImage
                      alt={post.featuredImage?.title}
                      image={post.featuredImage?.gatsbyImageData}
                    />
                  ) : (
                    <div className="flex items-center bg-oxford-blue aspect-video">
                      <Logo
                        alt="Copybara Logo"
                        className="my-8 mx-auto w-1/3 h-auto text-white"
                      />
                    </div>
                  )}
                </div>
                <div className="col-span-2">
                  <h2>
                    <Link to={`/blog/${post.slug}/`}>{post.title}</Link>
                  </h2>
                  <p>{post.excerpt.internal.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Tag;

export const query = graphql`
  query TagData($id: String) {
    posts: allContentfulBlog(
      sort: { fields: publishDate, order: DESC }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $id } } } }
      }
    ) {
      nodes {
        id
        title
        slug
        featuredImage {
          title
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
        }
        publishDate
        excerpt {
          internal {
            content
          }
        }
      }
    }
    tag: contentfulTag(contentful_id: { eq: $id }) {
      contentful_id
      name
    }
  }
`;
